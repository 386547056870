import React, { useEffect, useState } from 'react';

import type { CoverPictureId } from '@confluence/page-cover-picture';
import { propertyToId, RendererPageCoverPicture } from '@confluence/page-cover-picture';
import { ContentAppearanceType } from '@confluence/content-appearance';

import { HeaderImageContainer, NoHeaderImageSpacer } from './presenterModeStyles';
import { createHigherResImgSrc } from './utilities';

type HeaderImgProps = {
	contentId: string;
	coverPictureQueryData: any;
};

type CoverPictureData = {
	draft: null;
	published: CoverPictureId | null;
};

export const HeaderImageContainerId = 'presenter-mode-header-image-container';

export const HeaderImage = ({ contentId, coverPictureQueryData }: HeaderImgProps) => {
	const [coverPictureData, setCoverPictureData] = useState<CoverPictureData | undefined>(undefined);

	useEffect(() => {
		const publishedCoverPictureProperty =
			coverPictureQueryData?.content?.nodes?.[0]?.properties?.nodes?.[0] ?? undefined;

		if (!publishedCoverPictureProperty) {
			return;
		}

		const publishedId = propertyToId(publishedCoverPictureProperty);

		if (!publishedId) {
			return;
		}

		const coverPic = {
			draft: null,
			published: {
				value: {
					id: createHigherResImgSrc(publishedId?.value?.id),
					position: publishedId?.value?.position || 0,
				},
				version: publishedId?.version,
			},
		};

		setCoverPictureData(coverPic);
	}, [coverPictureQueryData]);

	if (!coverPictureData) {
		return <NoHeaderImageSpacer />;
	}

	return (
		<HeaderImageContainer id={HeaderImageContainerId}>
			<RendererPageCoverPicture
				contentId={contentId}
				isPagePreview={false}
				coverPicture={coverPictureData}
				contentAppearance={{
					draft: '',
					published: ContentAppearanceType.FULL_WIDTH,
				}}
				isSpaceOverview={false}
				isPresenterMode={true}
			/>
		</HeaderImageContainer>
	);
};
