import {
	TitleAlignmentType,
	useTitleContentPropertiesForPublishedPage,
} from '@confluence/custom-sites-extensions';

import { PresenterModePortalContainerId } from './PresenterModePortal';

type NodeItemType = HTMLElement | Element;

export enum TYPE_OF_NODE {
	LAYOUT = 'layout',
	HR = 'hr',
	EXPAND = 'expand',
	HEADING = 'header',
	EMPTY = 'empty',
	OTHER = 'other',
	STICKY_HEADER = 'stickyHeader',
	TABLE = 'table',
	MACRO = 'macro',
	IMAGE = 'image',
}

export const scrollFixedAmount = (scrollUp: boolean): void => {
	const scrollContainer: HTMLElement | null = document.getElementById(
		`${PresenterModePortalContainerId}`,
	);

	// Scroll by half the viewport height
	const scrollAmount = window.innerHeight * 0.5 * (scrollUp ? -1 : 1);

	if (
		!process.env.REACT_SSR &&
		typeof window !== 'undefined' &&
		typeof window.requestAnimationFrame === 'function'
	) {
		window.requestAnimationFrame(() => {
			scrollContainer?.scrollBy({
				top: scrollContainer.getBoundingClientRect().top + scrollAmount,
				behavior: 'smooth',
			});
		});
	}
};

export const createHigherResImgSrc = (originalSrc: string): string => {
	if (!originalSrc.includes('images.unsplash.com')) {
		return originalSrc;
	}

	const baseAndParams = originalSrc.split('?');
	const queryParams = new URLSearchParams(baseAndParams[1]);

	const width = Math.min(document?.documentElement?.clientWidth, 3000);
	queryParams.set('w', width.toString());
	queryParams.delete('q');

	return `${baseAndParams[0]}?${queryParams.toString()}`;
};

export const getFirstTextNode = (refNode: Node): Node | undefined => {
	const getFirstTextNodeIterative = (refNode) => {
		const stack = [refNode];
		while (stack.length > 0) {
			const node = stack.pop();
			if (node.nodeType == 3 && node.nodeValue?.trim().length !== 0) {
				return node;
			}
			for (let i = node.childNodes.length - 1; i >= 0; i--) {
				stack.push(node.childNodes[i]);
			}
		}
	};
	return getFirstTextNodeIterative(refNode);
};

export const isHeading = (node: NodeItemType): boolean => {
	return /^([Hh])[0-6].*/.test(node?.tagName);
};

export const isEmpty = (node: NodeItemType): boolean => {
	const firstTextNode = getFirstTextNode(node);
	if (isHeading(node) && !firstTextNode) return true;
	if (isLineBreak(node)) return true;
	const isImage =
		node.classList.contains('rich-media-item') ||
		!!node.querySelector('img') ||
		!!node.querySelector('svg');
	const isEmptyTextElement = !firstTextNode;
	return !isImage && isEmptyTextElement;
};

export const isImage = (node: NodeItemType): boolean => {
	return (
		node.classList.contains('rich-media-item') ||
		!!node.querySelector('img') ||
		!!node.querySelector('svg')
	);
};

export const isStickyHeaderOfTable = (node: NodeItemType): boolean => {
	return !!node.querySelector('.pm-table-sticky-wrapper');
};

export const isTable = (node: NodeItemType): boolean => {
	return (
		node.classList.contains('pm-table-container') ||
		(!!node.querySelector('.pm-table-container') && !isStickyHeaderOfTable(node))
	);
};

export const isLayout = (node: NodeItemType): boolean => {
	return node.getAttribute('data-layout-section') === 'true';
};

export const isHorizontalRule = (node: NodeItemType): boolean => {
	return node.tagName === 'HR';
};

export const isMacro = (node: NodeItemType): boolean => {
	return (
		node.classList.contains('ak-renderer-extension') ||
		!!node.querySelector('.ak-renderer-extension')
	);
};

export const isCodeBlock = (node: NodeItemType): boolean => {
	return node.classList.contains('code-block') || !!node.querySelector('.code-block');
};

export const isLineBreak = (node: NodeItemType): boolean => {
	return node.tagName === 'BR';
};

export const useIsTitleCenterAligned = (contentId?: string): boolean => {
	const { titleContentProperties } = useTitleContentPropertiesForPublishedPage({
		contentId,
	});

	return !!(titleContentProperties?.titleLayoutAlignment === TitleAlignmentType.CENTER);
};
